@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

.joining_screen_container {
    height: 100vh;
}
.joining_screen_container .logo {
    width: 193.15px;
    object-fit: cover;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    padding-top: 45px;
}
.joining_screen_container .joining_title {
    font-weight: 600;
    font-size: 32px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #f5f9ff;
    margin-top: 50px;
    font-family: 'Inter', sans-serif;
    display: flex;
    justify-content: center;
}
.avtar_container {
    position: absolute;
    bottom: 0;
    top: 0;
    display: flex;
    width: 100%;
}
.group_avtar_container {
    position: absolute;
    right: 0;
    top: 0;
}
.single_avtar_container {
    position: absolute;
    left: 0;
    bottom: -7px;
}
.group_avtar {
    max-width: 415px;
    width: 100%;
    object-fit: contain;
    max-height: 480px;
}
.joining_role_box_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6vh;
    width: 100%;
    margin-top: 8%;
}
.joining_role_box {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    border: 1px solid #fff;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9;
    width: 290px;
    height: 60px;
}
.joining_role_box.candidate {
    background: #133f7d;
    border: 1px solid #133f7d;
    margin-right: 40px;
}
.pointing_arrow {
    width: 4vw;
    margin-right: 50px;
}
.candidate_pointing_arrow {
    margin-left: -15px;
}
.joining_role_box.interviewer {
    background: #12b6bc;
    border: 1px solid #12b6bc;
}
.joining_role_box .role_text {
    color: rgba(255, 255, 255, 0.98);
    margin-left: 1vw;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.5px;
}
.role_box_arrow_container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.role_icon {
    height: 30px;
}
@media (max-width: 768px) {
    .joining_role_box {
        display: flex;
        width: 225px;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }
    .joining_screen_container .logo {
        padding-top: 25px;
    }
    .joining_screen_container .joining_title {
        font-size: 22px;
        margin-top: 30px;
    }
    .joining_role_box .role_text {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.5px;
    }
    .role_icon {
        height: 15px;
    }
    .joining_role_box_container {
        gap: 20px;
        margin-top: 0%;
    }
    .pointing_arrow {
        width: 6vw;
    }
}
@media (max-width: 967px) {
    .group_avtar,
    .pointing_arrow {
        display: none;
    }
    .joining_screen_container .logo {
        padding-top: 25px;
    }
}
@media (min-width: 1249px) and (max-width: 1400px) {
    .avtar {
        width: 80%;
    }
}
@media (max-width: 1248px) {
    .group_avtar {
        max-width: 325px;
    }
    .pointing_arrow {
        display: none;
    }
    .joining_role_box.candidate {
        margin-right: 0 !important;
    }
    .role_text {
        font-size: 1vw;
        margin-left: 0.8vw;
    }
}
@media (max-height: 560px){
    .joining_role_box{
        height: 45px !important;
    }
    .joining_role_box .role_text {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.5px;
    }
}
