.parent-container {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.transcript-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2e2e41;
    margin-bottom: 1vh;
}

.tab {
    width: 7.8vw;
    padding: 4px 10px;
    max-height: 4vh;
    margin-right: 1vw;
    border-radius: 2px;
    font-size: 0.6vw;
    text-align: center;
}

.active {
    background: #12b6bc;
    color: #ffffff;
}

.inactive {
    background: #ffffff;
    color: #000000;
}

.content-container {
    background: #191924;
    padding: 10px;
    overflow-y: auto;
    padding-bottom: 25px;
    flex: 1 1 auto;
}
