.transcript-wrapper {
    z-index: 1000;
    box-sizing: border-box;
    padding: 32px;
    position: absolute;
    width: 430px;
    min-height: 312px;
    left: calc(50% - 234px);
    top: 50%;
    transform: translateY(-50%);
    background: #191924;
    border: 1px solid #191924;
}

.transcript-popup-title {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    color: #f5f9fff2;
    margin-bottom: 15px;
}

.transcript_popup_div {
    width: 90%;
    height: 1px;
    background-color: #5e6267;
}
.transcript_img_container img {
    width: 104px;
    height: 104px;
}

.transcript_img_container img.transcript_fail_img {
    width: 84px;
    height: 84px;
    margin: 20px 0 10px 0;
}

.transcript_text {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.15px;
    text-align: center;
    font-weight: 400;
    color: #fff;
}

.common-btn.transcript_popup_btn {
    max-width: 112px;
    height: 32px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
}
